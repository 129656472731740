<template>
  <div>
    <v-row dense>
      <!-- ALL RECEIPTS -->
      <v-col cols="12" sm="6" class="pl-sm-3">
        <v-row dense>
          <v-col cols="12">
            <h3>
              Kaikki kulut aikavälillä {{ formatDate(reportDates.startDate) }} -
              {{ formatDate(reportDates.endDate) }}
            </h3>
          </v-col>
          <v-col cols="12">
            <p class="product-wrapper">
              <span>Kuluja yhteensä:</span>
              <strong>{{ receiptReportData.receipts.all.receiptCount }} kpl</strong>
            </p>
          </v-col>
        </v-row>

        <v-row dense>
          <v-col cols="12">
            <div>
              <h4 class="mb-1">Kulut</h4>

              <p class="product-wrapper">
                <span>Alviton:</span>
                <strong>{{
                  formatCurrency(
                    receiptReportData.receipts.all.totalAmount -
                      receiptReportData.receipts.all.vat255 -
                      receiptReportData.receipts.all.vat24 -
                      receiptReportData.receipts.all.vat14 -
                      receiptReportData.receipts.all.vat10
                  )
                }}</strong>
              </p>

              <p class="product-wrapper">
                <span>Alv 25,5 %:</span>
                <strong>
                  {{ formatCurrency(receiptReportData.receipts.all.vat255) }}
                </strong>
              </p>
              <p class="product-wrapper">
                <span>Alv 24 %:</span>
                <strong>
                  {{ formatCurrency(receiptReportData.receipts.all.vat24) }}
                </strong>
              </p>
              <p class="product-wrapper">
                <span>Alv 14 %:</span>
                <strong>
                  {{ formatCurrency(receiptReportData.receipts.all.vat14) }}
                </strong>
              </p>
              <p class="product-wrapper">
                <span>Alv 10 %:</span>
                <strong>
                  {{ formatCurrency(receiptReportData.receipts.all.vat10) }}
                </strong>
              </p>

              <p class="product-wrapper success--text">
                <strong>Yhteensä:</strong>
                <strong>
                  {{ formatCurrency(receiptReportData.receipts.all.totalAmount) }}
                </strong>
              </p>
            </div>

            <v-btn
              v-if="receiptReportData.receipts.all.receipts.length > 0"
              class="mt-2 mb-1"
              small
              @click="downloadReceiptList(receiptReportData.receipts.all.receipts, 'Kaikki kulut')"
              color="primary"
              >Lataa kaikki kulut</v-btn
            >
          </v-col>
        </v-row>
      </v-col>

      <!-- APARTMENT RECEIPTS -->
      <v-col cols="12" sm="6">
        <v-row dense>
          <v-col cols="12">
            <h3>
              Vuokrakohteiden kulut aikavälillä {{ formatDate(reportDates.startDate) }} -
              {{ formatDate(reportDates.endDate) }}
            </h3>
          </v-col>

          <v-col cols="12">
            <p class="product-wrapper">
              <span>Kuluja yhteensä:</span>
              <strong>{{ receiptsCount }} kpl</strong>
            </p>
          </v-col>
        </v-row>

        <v-row dense>
          <!-- Maintenance receipts -->
          <!-- <v-col cols="12" sm="6" class="pr-sm-1">
            <h4 class="mb-1">Vastikkeet</h4>

            <receipt-report-sums
              :receipts="receiptReportData.receipts.apartment.maintenanceReceipts"
            ></receipt-report-sums>

            <v-btn
              v-if="receiptReportData.receipts.apartment.maintenanceReceipts.apartments.length > 0"
              class="mt-2"
              small
              @click="
                downloadReceiptList(
                  receiptReportData.receipts.apartment.maintenanceReceipts.apartments,
                  'Vastikkeet kohteittain'
                )
              "
              color="primary"
              >Lataa vastikkeet</v-btn
            >
          </v-col> -->

          <!-- Other receipts -->
          <v-col cols="12" sm="6" class="pr-sm-1">
            <h4 class="mb-1">Kulut</h4>

            <receipt-report-sums
              :receipts="receiptReportData.receipts.apartment.otherReceipts"
            ></receipt-report-sums>

            <v-btn
              v-if="receiptReportData.receipts.apartment.otherReceipts.apartments.length > 0"
              class="mt-2"
              small
              @click="
                downloadReceiptList(
                  receiptReportData.receipts.apartment.otherReceipts.apartments,
                  'Muut kulut kohteittain'
                )
              "
              color="primary"
              >Lataa kulut</v-btn
            >
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import ReceiptReportSums from "./ReceiptReportSums.vue";
import mixins from "../../mixins/mixins";
import moment from "moment";

export default {
  mixins: [mixins],

  components: { ReceiptReportSums },

  computed: {
    ...mapState("report", ["receiptReportData", "reportDates"]),
    ...mapState("account", ["currentUser"]),

    receiptsCount() {
      return (
        this.receiptReportData.receipts.apartment.maintenanceReceipts.receiptCount +
        this.receiptReportData.receipts.apartment.otherReceipts.receiptCount
      );
    },
  },

  methods: {
    ...mapMutations("report", ["setLoadingRentReport"]),

    async downloadReceiptList(apartments, string) {
      try {
        const start = this.formatDate(this.reportDates.startDate);
        const end = this.formatDate(this.reportDates.endDate);

        const additionalInfo = [
          `${string}`,
          `Asiakas: ${this.currentUser.currentAccount.name}`,
          `Raportin aikaväli: ${start} - ${end}`,
          `Raportin päivämäärä: ${moment().format("DD.MM.YYYY")}`,
        ];

        const buffer = await this.createExcelBuffer(apartments, {
          addInfo: true,
          additionalInfo,
        });

        this.downloadExcel(
          buffer,
          `${string} aikaväliltä ${start}-${end}_${this.currentUser.currentAccount.name}`
        );
      } catch (err) {
        this.showPopup(err, "error");
      }
    },
  },
};
</script>

<style scoped>
.product-wrapper {
  max-width: 180px;
  display: flex;
  align-content: center;
  justify-content: space-between;
}
</style>
