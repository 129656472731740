<template>
  <div>
    <h3 class="mb-1">Salkkusi nyt</h3>

    <p>Vuokrakohteita salkussa: {{ rentReportData.currentState.apartmentsTotal }} kpl</p>
    <p>
      Vuokrattava pinta-ala kokonaisuudessaan:
      {{ roundDecimals(rentReportData.currentState.totalArea) }} m²
    </p>
    <p>
      Keskimääräinen pinta-ala:
      {{ roundDecimals(rentReportData.currentState.avgArea) }} m²
    </p>
    <p v-if="rentReportData.fixedTimeState.apartmentsTotal">
      {{ calcDifferences(rentReportData).apartmentDiffText }}
    </p>

    <v-row dense>
      <v-col cols="12" md="6">
        <h3 class="mb-1 mt-1">
          Salkkuraportti aikaväliltä {{ formatDate(reportDates.startDate) }} -
          {{ formatDate(reportDates.endDate) }}
        </h3>

        <p>Vuokrakohteita salkussa: {{ rentReportData.fixedTimeState.apartmentsTotal }} kpl</p>
        <p>
          Vuokrattava pinta-ala kokonaisuudessaan:
          {{ roundDecimals(rentReportData.fixedTimeState.totalArea) }} m²
        </p>
        <p>
          Keskimääräinen pinta-ala:
          {{ roundDecimals(rentReportData.fixedTimeState.avgArea) }} m²
        </p>

        <h4 class="mb-1 mt-1">Vuokrapotentiaali</h4>

        <p>
          Potentiaaliset vuokrakuukaudet yhteensä: ~{{
            roundDecimals(rentReportData.fixedTimeState.potentialRentMonths, 1)
          }}
          kk
        </p>

        <p>
          Todelliset tyhjät kuukaudet (potentiaaliset vähennettynä vuokratut): ~{{
            rentReportData.fixedTimeState.actualEmptyMonthsCount
          }}
          kk
          <span v-if="rentReportData.fixedTimeState.potentialRentMonths"
            >(~{{
              roundDecimals(
                (rentReportData.fixedTimeState.actualEmptyMonthsCount /
                  rentReportData.fixedTimeState.potentialRentMonths) *
                  100,
                1
              )
            }}
            %)</span
          >
        </p>

        <p>
          Tyhjänä olleiden kohteiden yhteenlasketut vuokrakuukaudet:
          {{ roundDecimals(rentReportData.fixedTimeState.unUsedRentMonths) }} kk
          <span v-if="rentReportData.fixedTimeState.potentialRentMonths">
            (~{{
              roundDecimals(
                (rentReportData.fixedTimeState.unUsedRentMonths /
                  rentReportData.fixedTimeState.potentialRentMonths) *
                  100,
                1
              )
            }}
            %)</span
          >
        </p>

        <h4 class="mb-1 mt-1">
          Kohteet, joissa on ollut voimassa oleva vuokrasopimus valitulla aikavälillä
        </h4>

        <p>Määrä: {{ rentReportData.fixedTimeState.rentedApartmentsCount }} kpl</p>
        <p v-if="rentReportData.fixedTimeState.apartmentsTotal">
          Osuus salkun kohteista:
          {{
            roundDecimals(
              (rentReportData.fixedTimeState.rentedApartmentsCount /
                rentReportData.fixedTimeState.apartmentsTotal) *
                100
            )
          }}
          %
        </p>

        <h4 class="mb-1 mt-1">Koko aikavälillä tyhjänä olleet kohteet</h4>

        <p>Määrä: {{ rentReportData.fixedTimeState.freeApartmentsCount }} kpl</p>

        <p v-if="rentReportData.fixedTimeState.apartmentsTotal">
          Osuus salkun kohteista:
          {{
            roundDecimals(
              (rentReportData.fixedTimeState.freeApartmentsCount /
                rentReportData.fixedTimeState.apartmentsTotal) *
                100
            )
          }}
          %
        </p>

        <h4 class="mb-1 mt-1">Käyttöaste</h4>

        <p>
          Käyttöaste vuokrattavien päivien mukaan:
          {{ roundDecimals(rentReportData.occupancyRate) }} %
        </p>
      </v-col>

      <!-- Charts -->
      <v-col v-if="chartData.labels.length > 0" cols="12" md="6">
        <v-row dense align="center">
          <v-col cols="12" sm="8">
            <apartments-pie-chart
              :labels="chartData.labels"
              :dataPoints="chartData.dataPoints"
              :backgroundColors="chartData.backgroundColors"
              :apartmentsTotal="chartData.apartmentsTotal"
              :avgAreaPerLabels="chartData.avgAreaPerLabels"
              :fontFamily="getFontFamily"
            ></apartments-pie-chart>
          </v-col>

          <v-col cols="12" sm="4" align="center">
            <div
              style="font-size: 11px"
              v-for="(item, i) in rentReportData.fixedTimeState.roomCountsArr"
              :key="i + 'a'"
            >
              <p>
                {{
                  item.roomCount
                    ? `${item.roomCount} huonetta: ${item.apartmentsTotal} kpl`
                    : `Ei määritetty: ${item.apartmentsTotal} kpl`
                }}
              </p>
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import mixins from "../../mixins/mixins";
import ApartmentsPieChart from "../../components/Charts/Reports/ApartmentsPieChart.vue";

export default {
  mixins: [mixins],

  components: {
    ApartmentsPieChart,
  },

  computed: {
    ...mapState("report", ["rentReportData", "chartData", "reportDates"]),
    ...mapGetters("account", ["getFontFamily"]),
  },

  methods: {
    calcDifferences(data) {
      const apartmentCountNow = data.currentState.apartmentsTotal;
      const apartmentCountTimeRange = data.fixedTimeState.apartmentsTotal;
      let apartmentDiffText = "";

      if (apartmentCountNow > apartmentCountTimeRange) {
        apartmentDiffText = `Salkkusi kohdemäärä on kasvanut ${this.roundDecimals(
          ((apartmentCountNow - apartmentCountTimeRange) / apartmentCountTimeRange) * 100
        )} % vertailuajankohtaan nähden.`;
      } else if (apartmentCountNow < apartmentCountTimeRange) {
        apartmentDiffText = `Salkkusi kohdemäärä on pienentynyt ${this.roundDecimals(
          ((apartmentCountNow - apartmentCountTimeRange) / apartmentCountTimeRange) * 100
        )} % vertailuajankohtaan nähden.`;
      } else {
        apartmentDiffText = "Salkkusi kohdemäärä on pysynyt samana vertailuajankohtaan nähden.";
      }
      return { apartmentDiffText };
    },
  },
};
</script>

<style></style>
