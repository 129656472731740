import moment from "moment";
import { roundDecimals } from "@/mixins/utilFunctions";

export const getReportDatesAndTime = (dates, endOfMonth = false) => {
  const start = moment(dates.startDate).format("DD.MM.YYYY");

  const end = endOfMonth
    ? moment(dates.endDate).endOf("month").format("DD.MM.YYYY")
    : moment(dates.endDate).format("DD.MM.YYYY");

  return {
    reportDateFormatted: `${moment().format("DD.MM.YYYY")}`,
    reportRangeFormatted: `${start} - ${end}`,
    formattedDates: { start, end },
  };
};

export const createAssignmentReportData = (itemMap) => {
  const items = Object.values(itemMap);

  return items.map((el) => {
    const {
      contractsAndInvoices,
      rentProductsAmount,
      rewardAmount,
      rewardAmountWithoutVat,
      rewardAmountVat,
      realtorRewards,
    } = getApartmentAssignmentInvoiceData(el);

    return {
      Toimeksiantajat: el.owners.map((el) => el.name).join("\n"),
      Vuokrakohde: getApartmentAddress(el),
      "Päällekkäisiä sopimuksia": el.concurrentContracts,
      Toimeksianto: el.assignment.assignmentEnd
        ? `${el.assignment.assignmentStart} - ${el.assignment.assignmentEnd}`
        : `${el.assignment.assignmentStart} - `,

      "Laskut ja sopimukset": contractsAndInvoices,
      Palkkiomalli: getAssignmentRewardData(el.assignment),
      "Laskutetut vuokrat": rentProductsAmount,
      "Palkkio sis. alv": rewardAmount,
      "Palkkio alv": rewardAmountVat,
      "Palkkio ilman alv": rewardAmountWithoutVat,
      Välittäjäpalkkiot: realtorRewards,
      Kommentti: el.assignment.comments,
    };
  });
};

export const createNetIncomeReportData = (
  accountingBase,
  timeRangeApartmentMap,
  cumulativeApartmentMap,
  end
) => {
  if (accountingBase == "accountDate") {
    return createAccountingBaseData(
      accountingBase,
      timeRangeApartmentMap,
      cumulativeApartmentMap,
      end
    );
  } else if (accountingBase == "paymentDate") {
    return createPaymentBaseData(
      accountingBase,
      timeRangeApartmentMap,
      cumulativeApartmentMap,
      end
    );
  }
};

export const createContractReportData = (contractMap) => {
  const items = Object.values(contractMap);

  return items.map((el) => {
    return {
      "Sopimusnro.": el.contractNumber,
      Vuokrasopimus: el.dates,
      Vuokrakohde: el.address,
      "Vuokrakohteen nro.": el.apartmentNumber,
      Vuokralainen: el.tenant,
      "Viimeisin laskun nro.": el.invoiceNumber,
      "Vuokra brutto": el.rentProductTotalWithTaxes,
      "Vuokratuotteita yht.": el.rentProductCount,
      "Vastaanotetut vakuudet": el.depositAmount,
      Vakuustavoite: el.depositTargetAmount,
    };
  });
};

export const getRentRollData = (apartmentMap) => {
  const items = Object.values(apartmentMap);

  return items.map((el) => {
    return {
      "Taloyhtiö / Kiinteistö": el.condominium,
      Osoite: getApartmentAddress(el),
      Tyyppi: `${el.houseType} | ${el.floorPlan}`,
      Kerros: el.floor,
      Vuokralaiset: el.tenants,
      "Vuokrasopimuksen alkamispäivä": el.contractStarts,
      "1. mahd. irtisanomispäivä": el.dismissalDates,
      "Vuokra / kk": roundDecimals(el.rent),
      Erilliskorvaukset: roundDecimals(el.otherIncomes),
      "Vuokran korotusehto": el.rentIncreaseType,
      "Indeksi aloituspisteluku": el.rentIncreaseIndexes,
      Prosenttikorotus: el.rentIncreasePercentages,
      "Kiinteä korotus": el.rentIncreaseFixed,
      "Muu korotus": el.rentIncreaseOwn,
    };
  });
};

// HELPERS

// Create accounting base data

function createAccountingBaseData(
  accountingBase,
  timeRangeApartmentMap,
  cumulativeApartmentMap,
  end
) {
  const startOfYear = moment(end).startOf("year").format("DD.MM.YYYY");
  const endOfYear = moment(end).endOf("year").format("DD.MM.YYYY");

  // Variables
  const items = [];
  const cumulativeKey = `Netto €\n${startOfYear}-${endOfYear}`;

  for (const id in timeRangeApartmentMap) {
    const el = timeRangeApartmentMap[id];

    items.push({
      Taloyhtiö: el.condominium,
      Osoite: el.address,
      "Vuokrakohteen\nnumero": el.apartmentNumber ? el.apartmentNumber : "",
      "Pinta-ala": el.area || "",
      Vuokrasopimus: getRentalContractDates(el.contract),
      Vuokrasaatavat: roundDecimals(el.rent.subAmountWithTax),
      Vesimaksusaatavat: roundDecimals(el.water.subAmountWithTax),
      Autopaikkasaatavat: roundDecimals(el.parking.subAmountWithTax),
      Mediamaksusaatavat: roundDecimals(el.media.subAmountWithTax),
      "Muut saatavat": roundDecimals(el.other.subAmountWithTax),
      "Hoitokulut\n(hoitovastike + vesi)": roundDecimals(
        el.maintenanceChargesTotal + el.waterChargesTotal
      ),
      Rahoitusvastikkeet: roundDecimals(el.totalLoans),
      "Muut kulut": roundDecimals(el.totalReceipts),
      Netto: roundDecimals(calcNetAmount(accountingBase, el)),
      "Netto €/m²": el.area
        ? roundDecimals(calcNetAmount(accountingBase, el) / el.area)
        : "Ei laskettavissa",
      [cumulativeKey]: roundDecimals(calcNetAmount(accountingBase, cumulativeApartmentMap[id])),
    });
  }

  return items;
}

function createPaymentBaseData(accountingBase, timeRangeApartmentMap, cumulativeApartmentMap, end) {
  const startOfYear = moment(end).startOf("year").format("DD.MM.YYYY");
  const endOfYear = moment(end).endOf("year").format("DD.MM.YYYY");

  // Variables
  const items = [];
  const cumulativeKey = `Netto €\n${startOfYear}-${endOfYear}`;

  for (const id in timeRangeApartmentMap) {
    const el = timeRangeApartmentMap[id];

    items.push({
      Taloyhtiö: el.condominium,
      Osoite: el.address,
      "Vuokrakohteen\nnumero": el.apartmentNumber ? el.apartmentNumber : "",
      "Pinta-ala": el.area || "",
      Vuokrasopimus: getRentalContractDates(el.contract),
      Maksusuoritukset: roundDecimals(el.totalPayments),
      "Hoitokulut\n(hoitovastike + vesi)": roundDecimals(
        el.maintenanceChargesTotal + el.waterChargesTotal
      ),
      Rahoitusvastikkeet: roundDecimals(el.totalLoans),
      "Muut kulut": roundDecimals(el.totalReceipts),
      Netto: roundDecimals(calcNetAmount(accountingBase, el)),
      "Netto €/m²": el.area
        ? roundDecimals(calcNetAmount(accountingBase, el) / el.area)
        : "Ei laskettavissa",
      [cumulativeKey]: roundDecimals(calcNetAmount(accountingBase, cumulativeApartmentMap[id])),
    });
  }

  return items;
}

function calcNetAmount(accountingBase, el) {
  if (accountingBase == "accountDate") {
    return (
      el.rent.subAmountWithTax +
      el.water.subAmountWithTax -
      el.maintenanceChargesTotal -
      el.waterChargesTotal -
      el.totalLoans
    );
  } else if (accountingBase == "paymentDate") {
    return el.totalPayments - el.maintenanceChargesTotal - el.waterChargesTotal - el.totalLoans;
  } else return 0;
}

function getRentalContractDates(contract) {
  if (!contract) return "";
  const startDate = contract.startDateFormatted;
  const endDate = contract.endDateFormatted || "";
  return `${startDate} - ${endDate}`;
}

function getApartmentAddress(apartment) {
  const address = apartment.address;
  const apartmentNumber = apartment.apartmentNumber || null;

  return apartmentNumber ? `${address}, ${apartmentNumber}` : address;
}

function getApartmentAssignmentInvoiceData(item) {
  const rewardType = item.assignment.rewardType;
  let contractsAndInvoices = "";
  let rentProductsAmount = 0;
  let rewardAmount = 0;
  let rewardAmountWithoutVat = 0;
  let rewardAmountVat = 0;
  let realtorRewards = {};

  if (rewardType === "percent") {
    const invoices = item.invoiceData;

    invoices.forEach((el, idx) => {
      const lastItem = idx === invoices.length - 1;

      contractsAndInvoices += lastItem
        ? `Sopimus ${el.contractNumber}, lasku ${el.invoiceNumber}`
        : `Sopimus ${el.contractNumber}, lasku ${el.invoiceNumber}\n`;
      rentProductsAmount += el.rentProductsAmount;
      rewardAmount += el.rewardAmount;
      rewardAmountWithoutVat += el.rewardAmountWithoutVat;
      rewardAmountVat += el.rewardAmountVat;
      el.realtorRewards.forEach((r) => {
        if (!realtorRewards[r._id]) {
          realtorRewards[r._id] = {
            name: r.name,
            rewardPercent: r.rewardPercent,
            totalRewardAmount: 0,
          };
        }

        realtorRewards[r._id].totalRewardAmount += r.rewardAmount;
      });
    });
  } else if (rewardType === "fixed") {
    rewardAmount = item.assignment.calculatedFixedAmount;
    rewardAmountWithoutVat = rewardAmount / 1.255;
    rewardAmountVat = rewardAmount - rewardAmountWithoutVat;
    item.assignment.realtors.forEach((r) => {
      // Lasketaan yhteen realtoreiden palkkiot
      const rewardAmountFixed = calcRealtorRewardAmountFixed(
        r.rewardPercent,
        rewardAmountWithoutVat
      );

      if (!realtorRewards[r._id]) {
        realtorRewards[r._id] = {
          name: r.name,
          rewardPercent: r.rewardPercent,
          totalRewardAmount: 0,
        };
      }

      realtorRewards[r._id].totalRewardAmount += rewardAmountFixed;
    });
  }
  // Pyöristä summat
  rentProductsAmount = roundDecimals(rentProductsAmount);
  rewardAmount = roundDecimals(rewardAmount);
  rewardAmountWithoutVat = roundDecimals(rewardAmountWithoutVat);
  rewardAmountVat = roundDecimals(rewardAmountVat);

  // Luodaan listan realtoreiden tiedoista
  const realtorRewardsList = Object.values(realtorRewards).map((realtor) => ({
    name: realtor.name,
    rewardPercent: realtor.rewardPercent,
    rewardAmount: realtor.totalRewardAmount,
  }));

  // Pyöristetään palkkiot lopuksi
  realtorRewardsList.forEach((realtor) => {
    realtor.rewardAmount = roundDecimals(realtor.rewardAmount);
  });

  return {
    contractsAndInvoices,
    rentProductsAmount,
    rewardAmount,
    rewardAmountWithoutVat,
    rewardAmountVat,
    realtorRewards: realtorRewardsList
      .map((r) => `${r.name} (${r.rewardPercent} %) ${r.rewardAmount} €`)
      .join("\n"),
  };
}

function calcRealtorRewardAmountFixed(percent, amount) {
  return parseFloat(amount * (percent / 100));
}

function getAssignmentRewardData(assignment) {
  let amount;
  if (assignment.rewardType === "percent") {
    amount = `${assignment.percent + 100} %`;
  } else if (assignment.rewardType === "fixed") {
    amount = `${assignment.fixedAmountPerMonth} €`;
  }

  let rewardType = "";

  switch (assignment.rewardType) {
    case "percent":
      rewardType = "Prosenttiosuus";
      break;
    case "fixed":
      rewardType = "Kiinteä / kk";
      break;
    default:
      rewardType = "Prosenttiosuus";
  }

  return `${rewardType} (${amount})`;
}
